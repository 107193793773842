import React from 'react';

const TryPage = () => (
  <div className="try-page">
    <div className="title-container">
      <h1>The Wisdom Alignment Project</h1>
      <p className="explanatory-text">
      biological and silicon-based agents making each other better
      </p>
    </div>
    <div className="iframe-container">
    <iframe 
        className="iframe-content"
        //src="https://s3.us-west-1.amazonaws.com/cloudraga.com/index.html" 
        src={`${process.env.PUBLIC_URL}/tryframe.html`}
        title="content"
    ></iframe>
    </div>
  </div>
);

export default TryPage;
