import React from 'react';

const FAQPage = () => (
  <div className="faq-page">
    <div className="title-container">
      <h1>The Wisdom Alignment Project</h1>
      <p className="explanatory-text">
      biological and silicon-based agents making each other better
      </p>
    </div>
    <div className="content">
      <div className="welcome-text">
        <p>
        Q: What is The Wisdom Alignment Project?
        <br />A: The Wisdom Alignment Project is an endeavor to provide users the opportunity to expand their own world models while contributing to the project of creating a consensus world model. In the process, the consensus model gets "wiser" over time and, implemented as an interactive AI agent, can be used as a sounding board for users attempting understand and model their own world in areas that include science, ethics, and philosophy.
        <br />
        <br />Q: How can I participate?
        <br />A: Currently, you have the ability to interact with the wisdom oracle on this site in order to test your wisdom against it. In upcoming versions, you will be able to record your findings in your own world model workspace, suggest changes to the wisdom oracle's consensus world model, and chat or collaboratively debate with others on the site.
        <br />
        <br />Q: When will I be able to create an account and record aspects of my world model?
        <br />A: This is a prototype version of the project. Stay tuned to hear news of updates.
        <br />
        <br />Q: What kind of knowledge does the wisdom oracle have?
        <br />A: The current (alpha) version of the oracle has been configured with a set of ethical, philosophical, and scientific opinions, all of which are speculative and not necessarily orthodox. The idea is to spur thought and debate.
        <br />
        </p>
      </div>
    </div>
  </div>
);

export default FAQPage;
