import React from 'react';
import wiseImage from '../assets/wise.jpg';
import { useNavigate } from 'react-router-dom';


const HomePage = () => {
    const navigate = useNavigate();
  
    const handleClick = () => {
      navigate('/try');
    };

    return (
    <div className="home-page">
        <div className="title-container">
          <h1>The Wisdom Alignment Project</h1>
           <p className="explanatory-text">
            biological and silicon-based agents making each other better
           </p>
        </div>
        <div className="content">
          <div className="welcome-container">
            <div className="welcome-text">
              <p>
              Collaborate with advanced AI to expand your world model and contribute to global understanding
              </p>
              <button onClick={handleClick}>Try it!</button>
            </div>
          </div>
          <div className="home-image">
            <img src={wiseImage} alt="Home" />
          </div>
        </div>
    </div>
    );
};

export default HomePage;
