import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../assets/logo.png'; // Replace this with your logo's relative path

const Header = () => (
  <header>
    <div className="logo-container">
      <Link to="/">
        <img src={logo} alt="Logo" />
      </Link>
    </div>
    <nav>
      <ul>
        <li>
          <Link to="/">Home</Link>
        </li>
        <li>
          <Link to="/about">About</Link>
        </li>
        <li>
          <Link to="/faq">FAQ</Link>
        </li>
        <li>
          <Link to="/blog">Blog</Link>
        </li>
      </ul>
    </nav>
  </header>
);

export default Header;
