import React from 'react';

const BlogPage = () => {

  const openSubstack = () => {
    window.open('https://wisdomalignmentproject.substack.com', '_blank');
  };

  return (
    <div className="blog-page">
      <div className="title-container">
        <h1>The Wisdom Alignment Project</h1>
        <p className="explanatory-text">
          biological and silicon-based agents making each other better
        </p>
      </div>
      <div className="content">
        <div className="welcome-text">
          <button onClick={openSubstack} className="btn btn-primary">
            Open my Substack...
          </button>
        </div>
      </div>
    </div>
  );
};

export default BlogPage;
