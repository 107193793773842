import React from 'react';

const AboutPage = () => (
  <div className="about-page">
    <div className="title-container">
      <h1>The Wisdom Alignment Project</h1>
      <p className="explanatory-text">
      biological and silicon-based agents making each other better
      </p>
    </div>
    <div className="content">
      <div className="welcome-text">
        <p>
        The Wisdom Alignment Project provides users the opportunity to expand their own world models while contributing to the project of creating a consensus world model. In the process, the consensus model gets "wiser" over time and, implemented as an interactive AI agent, can be used as a sounding board for users attempting to understand and model their own world in areas that include science, ethics, and philosophy. 
        </p>
      </div>
    </div>
  </div>
);

export default AboutPage;
